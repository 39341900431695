export const headerNavItems = [
  {
    title: "nav.cashback",
    path: "/private",
    hash: "",
  },
  // {
  //   title: "nav.whitelabel",
  //   path: "/whitelabel",
  //   hash: "",
  // },
  // {
  //   title: "nav.shopping",
  //   path: "/worldwide",
  //   hash: "",
  // },
  {
    title: "nav.pricing",
    path: "/cards",
    hash: "",
  },
  {
    title: "nav.extension",
    path: "/extension",
    hash: "",
    icon: "chrome-color",
  },
];

export const footerNavItems = [
  // {
  //   title: "nav.whitelabel",
  //   path: "/whitelabel",
  // },
  {
    title: "nav.private",
    path: "/private",
  },
  {
    title: "nav.pulse",
    href: "https://pulse.pst.net",
  },
  {
    title: "nav.affiliate",
    path: "/affiliate",
  },
  {
    title: "nav.blog",
    href: "https://blog.pst.net",
  },
  {
    title: "nav.legal",
    path: "/legal",
  },
  {
    title: "nav.faq",
    path: "/faq",
  }
];


export const mobileNavItems = [
  {
    path: "/cards",
    href: "",
    title: "nav.pricing",
    icon: "pst-visa-small",
    iconPath: "cards"
  },
  {
    path: "/private",
    href: "",
    title: "nav.cashback",
    iconPath: ""
  },
  // {
  //   path: "/private",
  //   href: "",
  //   title: "nav.pstPrivate",
  //   icon: "diamond-simple",
  //   iconPath: ""
  // },
  // {
  //   path: "/whitelabel",
  //   href: "",
  //   title: "nav.whitelabel",
  //   icon: "",
  //   iconPath: ""
  // },
  {
    path: "",
    href: "https://pulse.pst.net",
    title: "nav.pulse",
    icon: "",
    iconPath: ""
  },
  {
    path: "/affiliate",
    href: "",
    title: "nav.affiliate",
    icon: "",
    iconPath: ""
  },
  {
    path: "",
    href: "https://blog.pst.net",
    title: "nav.blog",
    icon: "",
    iconPath: ""
  },
  {
    path: "/legal",
    href: "",
    title: "nav.legal",
    icon: "",
    iconPath: ""
  }
]
