<script setup lang="ts">
import { Icon } from "@/components/Ui";

interface Props {
  item?: {
    path?: string;
    hash?: string;
    title?: string;
    icon?: string;
  };
}

const props = defineProps<Props>();
const localePath = useLocalePath();
</script>

<template>
  <li :class="$style.root">
    <nuxt-link
      :to="localePath({ path: props.item.path, hash: props.item.hash || '' })"
      :title="$t(props.item.title)"
      :replace="!!props.item.hash"
    >
      {{ $t(props.item.title) }}
      <Icon
        v-if="props.item.icon"
        :name="props.item.icon"
      />
    </nuxt-link>
  </li>
</template>

<style lang="scss" module>
.root {
  & > a {
    @apply text-[16px] py-[6px] px-[10px] transition-all flex items-center gap-[6px] rounded-[12px];

    & svg {
      @apply w-6 h-6 min-w-[24px];
    }

    &:hover:not(:global(.router-link-active)) {
      @apply bg-black bg-opacity-[0.08] dark:bg-white dark:bg-opacity-[0.08];
    }
  }

  :global(.router-link-active) {
    @apply bg-black bg-opacity-[0.16] dark:bg-white dark:bg-opacity-[0.16];
  }
}
</style>
