<script setup lang="ts">
import { Modal, Button, Icon } from "@/components/Ui";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";
import { ref } from "vue";

interface Props {
  items?: any;
}

const props = defineProps<Props>();
const emit = defineEmits(["close"]);
const dir = useTextDirection();

const isReady = ref<boolean>(false);
onNuxtReady(() => {
  isReady.value = true;
});
</script>

<template>
  <Modal
    position="center-center"
    mode="light"
    transition="slide-down2"
    closable
    fixed
    fullscreen
    :close-size="44"
    :modal-class="menu.root"
    @close="emit('close')"
  >
    <template #title>
      <nuxt-link
        :to="localePath('/')"
        aria-label="PST.NET - Virtual cards for payments. Crypto-fiat exchange"
        :class="menu.logo"
      >
        <Icon
          name="pst-logo"
          path="./"
        />
      </nuxt-link>
    </template>
    <template #close>
      <span :class="menu.close">
        <Icon name="close-outline" />
      </span>
    </template>
    <div :class="menu.wrapper">
      <nav :class="menu.nav">
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <nuxt-link
              v-if="item.path"
              :to="localePath({ path: item.path })"
              :title="$t(item.title)"
              @click="emit('close')"
            >
              {{ $t(item.title) }}

              <Icon
                v-if="item.icon"
                :name="item.icon"
                :path="item.iconPath"
                :class="menu[`nav-icon-${item.iconPath || 'base'}`]"
              />
            </nuxt-link>
            <a
              v-else
              :href="item.href"
              :title="item.title"
              target="_blank"
            >
              {{ $t(item.title) }}
            </a>
          </li>
        </ul>
      </nav>
      <Button
        :disabled="!isReady"
        :title="$t('btn.register')"
        color="orange"
        icon="arrow-outline-right"
        icon-pos="right"
        :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
        size="custom"
        :class="menu.button"
        @click="onDashboard(
                'create account started',
                '/register',
                {
                  'cta type': 'button',
                  'cta location': 'Header: Mobile sidebar',
                  'cta text': 'Sign up',
                },
                {},
                true
              )"
      />
    </div>
  </Modal>
</template>

<style lang="scss" module="menu">
.root {
  @apply overflow-y-auto p-5;
  @apply bg-white bg-opacity-90 backdrop-blur-[12px];
  @apply dark:bg-neutral-900 dark:bg-opacity-90 dark:backdrop-blur-[12px];
}

.wrapper {
  @apply relative flex flex-col h-full;
  @apply md:items-center;
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:min-w-[360px] md:w-fit md:relative md:mt-12;
  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}

.logo svg {
  @apply h-[48px];
  @apply dark:text-white
}

.close {
  @apply ltr:ml-auto rtl:mr-auto flex items-center justify-center h-12 w-12 rounded-full bg-black bg-opacity-20 text-white;
  @apply transition-all focus:bg-black focus:bg-opacity-30 focus:outline-none;
  @apply cursor-pointer transition-all;
  @apply hover:scale-[0.9];
  @apply dark:bg-white dark:bg-opacity-20;

  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}

.nav {
  @apply pt-5 xs:pt-6;

  & > ul {
    @apply flex sm:items-center gap-5 xs:gap-7 flex-col;
  }

  & a {
    @apply text-[18px] xs:text-[20px] flex items-center gap-[10px] w-fit;
    @apply dark:text-white;
  }

  &-icon {
    &-base {
      @apply w-6 h-6 min-w-[24px];
    }

    &-cards {
      @apply w-[43px] h-[28px];
    }
  }
}
</style>