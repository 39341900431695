<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import ToggleLanguage from "@/components/Layout/ToggleLanguage.vue";
import HeaderNav from "@/components/Layout/HeaderNav.vue";
import MobileMenu from "@/components/Layout/MobileMenu.vue";
import ToggleMobileMenu from "@/components/Layout/ToggleMobileMenu.vue";
import { onDashboard, onEvent } from "@/helpers/events";
import { headerNavItems, mobileNavItems } from "@/config/layouts";
import { isMobile, isMobileOrTablet } from "@/helpers/breakpoints";
import { ref } from "vue";

interface Props {
  fixed?: boolean;
}

const props = defineProps<Props>();
const localePath = useLocalePath();
const showMobileNav = ref<boolean>(false);
const contentEl = ref<HTMLElement | null>(null);
const contentSize = useElementBounding(contentEl);

const toggleMobileNav = () => {
  showMobileNav.value = !showMobileNav.value;
};

const isReady = ref<boolean>(false);
onNuxtReady(() => {
  isReady.value = true;
});
</script>
<template>
  <header>
    <div
      :class="header.root"
      class="container justify-between"
    >
      <nuxt-link
        :to="localePath('/')"
        aria-label="PST.NET - Virtual cards for payments. Crypto-fiat exchange"
      >
        <Icon
          v-if="contentSize?.left?.value > 120"
          name="pst-logo-v2"
          path="./"
          class="h-6 md:hidden"
        />
        <Icon
          v-else
          name="pst-icon"
          path="./"
          class="h-8 xs:h-[38px] w-auto md:hidden"
        />

        <Icon
          name="pst-logo"
          path="./"
          class="w-[164px] hidden md:block"
        />
      </nuxt-link>
      <div
        ref="contentEl"
        class="ltr:ml-auto rtl:mr-auto grid grid-flow-col w-fit items-center h-full gap-2 md:gap-3">
        <HeaderNav
          :class="[header.nav, header[`nav-${$i18n.locale}`]]"
          :items="headerNavItems"
        />
        <ToggleLanguage
          view="row"
          size="small"
          :only-icon="isMobileOrTablet"
          class="order-2"
          :badge-white="!props.fixed && $route.name?.includes('index___') && isMobile"
          position="header"
          :chevron="!isMobile"
        />
        <Button
          :disabled="!isReady"
          :title="$t('btn.login')"
          size="custom"
          color="custom"
          class="order-3 md:!hidden"
          :class="[header.button, header[`button-login-${!props.fixed && $route.name?.includes('index___') ? 'homepage' : 'default'}`]]"
          @click="
              onDashboard(
                'login_button_click',
                '/login',
                {
                  'cta type': 'button',
                  'cta location': 'Header',
                  'cta text': 'Sign in',
                },
                {},
                true
              )
            "
        />
        <Button
          :disabled="!isReady"
          :title="$t('btn.login')"
          color="custom"
          size="custom"
          :class="[
              header.button,
              $route.meta.page === 'private' ? header['button-light'] : header['button-grey'],
            ]"
          class="order-3 !hidden md:!flex"
          @click="
              onDashboard(
                'login_button_click',
                '/login',
                {
                  'cta type': 'button',
                  'cta location': 'Header',
                  'cta text': 'Sign in',
                },
                {},
                true
              )
            "
        />
        <Button
          :disabled="!isReady"
          :title="$t('btn.register')"
          color="orange"
          size="custom"
          icon="add-outlined"
          icon-pos="right"
          icon-class="w-6 h-6 md:w-4 md:h-4"
          class="order-4"
          :class="[header.button, header[`button-register-${$i18n.locale}`]]"
          @click="
              onDashboard(
                'create account started',
                '/register',
                {
                  'cta type': 'button',
                  'cta location': 'Header',
                  'cta text': 'Sign up',
                },
                {},
                true
              )
            "
        />

        <ToggleMobileMenu
          :is-active="showMobileNav"
          :is-white="!props.fixed && $route.name?.includes('index___') && isMobile"
          class="order-5 md:order-1"
          @click="toggleMobileNav(); onEvent('landing_v2_cards-header');"
        />
      </div>
    </div>
    <Teleport to="body">
      <MobileMenu v-if="showMobileNav" :items="mobileNavItems" @close="toggleMobileNav" />
    </Teleport>

  </header>
</template>

<style lang="scss" module="header">
.root {
  @apply px-2 grid grid-flow-col z-20 relative w-full items-center gap-2;
  @apply md:px-4 md:gap-3;
}

.close {
  @apply ml-auto flex items-center justify-center h-12 w-12 rounded-full bg-black bg-opacity-20 text-white;
  @apply transition-all focus:bg-black focus:bg-opacity-30 focus:outline-none;

  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}

.nav {
  @apply order-1 hidden lg:block;
}

.modal {
  @apply overflow-y-auto;
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;

  &-wrapper {
    @apply relative flex flex-col h-full;
  }

  &-button {
    @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];

    & svg {
      @apply w-6 h-6 min-w-[24px];
    }
  }
}

.button {
  @apply h-10 xs:h-12 px-[10px] text-[15px] border-none;
  @screen md {
    @apply h-auto text-[16px] py-[6px] px-3;
  }


  &-grey {
    @apply bg-black bg-opacity-[0.16]
  }

  &-light {
    @apply bg-white bg-opacity-[0.16] text-white;
  }

  &-login-homepage {
    @apply bg-white bg-opacity-20 border-white border-opacity-[0.05] text-white;
    @screen md {
      @apply bg-black bg-opacity-[0.16] text-neutral-800;
    }
  }

  &-login-default {
    @apply bg-black bg-opacity-[0.16] border-black border-opacity-[0.01] text-neutral-800;
    @apply dark:bg-white dark:bg-opacity-[0.2] dark:text-white;

    @screen md {
      @apply bg-white bg-opacity-[0.16] text-white;
    }
  }

  &-register {
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-it,
    &-ru,
    &-pt,
    &-hi,
    &-ua,
    &-ja,
    &-zh,
    &-bg,
    &-th,
    &-ar,
    &-ko,
    &-sw {
      @apply hidden xxs:flex;
    }
    &-th,
    &-vi,
    &-hi,
    &-bn,
    &-pl,
    &-fr,
    &-es,
    &-de {
      @apply hidden 414:flex;
    }
    &-tr {
      @apply hidden xs:flex;
    }
  }
}


.logo {
  &-full {
    &-en {
      @apply block xxs:hidden 414:block;
    }

    &-ru {
      @apply hidden 414:block;
    }
  }

  &-icon {
    &-en {
      @apply hidden xxs:block 414:hidden;
    }

    &-ru {
      @apply 414:hidden;
    }
  }
}
</style>